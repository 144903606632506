// src/data/calculatorData.js

export const calculators = [
  {
    id: 'bsa-calculator-for-methotrexate',
    name: 'BSA Calculator for Methotrexate',
    description: 'Calculate Body Surface Area for Methotrexate dosing.',
    method: 'mosteller',
    howToCalculate: 'The BSA for Methotrexate dosing is typically calculated using the Mosteller formula: BSA (m²) = √((Height(cm) x Weight(kg)) / 3600)',
    exampleCalculation: 'For a patient weighing 70 kg and 170 cm tall: BSA = √((170 x 70) / 3600) = 1.81 m²',
    popularUses: [
      'Determining Methotrexate dosage for rheumatoid arthritis',
      'Calculating Methotrexate dose for psoriasis treatment',
      'Adjusting Methotrexate dose in cancer therapy'
    ],
    referenceChart: [
      { weight: 50, height: 150, bsa: 1.43 },
      { weight: 60, height: 160, bsa: 1.62 },
      { weight: 70, height: 170, bsa: 1.81 },
      { weight: 80, height: 180, bsa: 2.00 },
      { weight: 90, height: 190, bsa: 2.19 }
    ]
  },
  {
    id: 'bsa-calculator-mosteller',
    name: 'BSA Calculator (Mosteller)',
    description: 'Calculate Body Surface Area using the Mosteller formula.',
    method: 'mosteller',
    howToCalculate: 'The Mosteller formula for BSA is: BSA (m²) = √((Height(cm) x Weight(kg)) / 3600)',
    exampleCalculation: 'For a person weighing 75 kg and 175 cm tall: BSA = √((175 x 75) / 3600) = 1.91 m²',
    popularUses: [
      'General BSA calculations',
      'Determining drug dosages',
      'Estimating metabolic rate'
    ],
    referenceChart: [
      { weight: 55, height: 155, bsa: 1.53 },
      { weight: 65, height: 165, bsa: 1.72 },
      { weight: 75, height: 175, bsa: 1.91 },
      { weight: 85, height: 185, bsa: 2.10 },
      { weight: 95, height: 195, bsa: 2.29 }
    ]
  },
  {
    id: 'bsa-calculator-for-chemotherapy',
    name: 'BSA Calculator for Chemotherapy',
    description: 'Calculate Body Surface Area for Chemotherapy dosing.',
    method: 'dubois',
    howToCalculate: 'The BSA for Chemotherapy is usually calculated using the DuBois and DuBois formula: BSA (m²) = 0.007184 x Height(cm)^0.725 x Weight(kg)^0.425',
    exampleCalculation: 'For a patient weighing 80 kg and 180 cm tall: BSA = 0.007184 x 180^0.725 x 80^0.425 = 2.01 m²',
    popularUses: [
      'Determining chemotherapy drug dosages',
      'Adjusting medication doses in cancer treatment',
      'Calculating appropriate fluid volumes for chemotherapy administration'
    ],
    referenceChart: [
      { weight: 60, height: 160, bsa: 1.63 },
      { weight: 70, height: 170, bsa: 1.82 },
      { weight: 80, height: 180, bsa: 2.01 },
      { weight: 90, height: 190, bsa: 2.20 },
      { weight: 100, height: 200, bsa: 2.39 }
    ]
  },
  {
    id: 'pediatric-bsa-calculator',
    name: 'Pediatric BSA Calculator',
    description: 'Calculate Body Surface Area for pediatric patients.',
    method: 'haycock',
    howToCalculate: 'The Haycock formula is often used for pediatric BSA: BSA (m²) = 0.024265 x Height(cm)^0.3964 x Weight(kg)^0.5378',
    exampleCalculation: 'For a child weighing 20 kg and 110 cm tall: BSA = 0.024265 x 110^0.3964 x 20^0.5378 = 0.79 m²',
    popularUses: [
      'Calculating medication doses for children',
      'Determining fluid requirements in pediatric care',
      'Assessing growth and development in children'
    ],
    referenceChart: [
      { weight: 10, height: 80, bsa: 0.47 },
      { weight: 15, height: 95, bsa: 0.64 },
      { weight: 20, height: 110, bsa: 0.79 },
      { weight: 25, height: 125, bsa: 0.94 },
      { weight: 30, height: 140, bsa: 1.09 }
    ]
  },
  {
    id: 'halls-md-bmi-calculator',
    name: "Hall's MD BMI Calculator",
    description: 'Calculate Body Mass Index (BMI) using Hall\'s method.',
    method: 'bmi',
    howToCalculate: 'BMI is calculated as: BMI = Weight(kg) / (Height(m))²',
    exampleCalculation: 'For a person weighing 70 kg and 1.75 m tall: BMI = 70 / (1.75)² = 22.86 kg/m²',
    popularUses: [
      'Assessing weight status in adults',
      'Screening for weight categories that may lead to health problems',
      'Tracking weight changes in population studies'
    ],
    referenceChart: [
      { weight: 50, height: 160, bsa: 19.5 },
      { weight: 60, height: 170, bsa: 20.8 },
      { weight: 70, height: 180, bsa: 21.6 },
      { weight: 80, height: 190, bsa: 22.2 },
      { weight: 90, height: 200, bsa: 22.5 }
    ]
  },
  {
    id: 'body-surface-area-calculator-dubois',
    name: 'Body Surface Area Calculator (DuBois)',
    description: 'Calculate Body Surface Area using the DuBois and DuBois formula.',
    method: 'dubois',
    howToCalculate: 'The DuBois and DuBois formula for BSA is: BSA (m²) = 0.007184 x Height(cm)^0.725 x Weight(kg)^0.425',
    exampleCalculation: 'For a person weighing 70 kg and 170 cm tall: BSA = 0.007184 x 170^0.725 x 70^0.425 = 1.80 m²',
    popularUses: [
      'Clinical drug dosing',
      'Cardiac output measurements',
      'Burn area estimation'
    ],
    referenceChart: [
      { weight: 55, height: 155, bsa: 1.54 },
      { weight: 65, height: 165, bsa: 1.72 },
      { weight: 75, height: 175, bsa: 1.90 },
      { weight: 85, height: 185, bsa: 2.08 },
      { weight: 95, height: 195, bsa: 2.26 }
    ]
  },
  {
    id: 'bsa-m2-calculator',
    name: 'BSA m² Calculator',
    description: 'Calculate Body Surface Area in square meters.',
    method: 'mosteller',
    howToCalculate: 'This calculator uses the Mosteller formula: BSA (m²) = √((Height(cm) x Weight(kg)) / 3600)',
    exampleCalculation: 'For a person weighing 75 kg and 175 cm tall: BSA = √((175 x 75) / 3600) = 1.91 m²',
    popularUses: [
      'Standardizing physiological functions',
      'Calculating drug doses',
      'Estimating metabolic rate'
    ],
    referenceChart: [
      { weight: 60, height: 160, bsa: 1.63 },
      { weight: 70, height: 170, bsa: 1.81 },
      { weight: 80, height: 180, bsa: 2.00 },
      { weight: 90, height: 190, bsa: 2.18 },
      { weight: 100, height: 200, bsa: 2.37 }
    ]
  },
  {
    id: 'oncology-bsa-calculator',
    name: 'Oncology BSA Calculator',
    description: 'Calculate Body Surface Area for oncology treatments.',
    method: 'dubois',
    howToCalculate: 'In oncology, the DuBois and DuBois formula is often used: BSA (m²) = 0.007184 x Height(cm)^0.725 x Weight(kg)^0.425',
    exampleCalculation: 'For a patient weighing 80 kg and 180 cm tall: BSA = 0.007184 x 180^0.725 x 80^0.425 = 2.01 m²',
    popularUses: [
      'Determining chemotherapy dosages',
      'Calculating radiation therapy fields',
      'Assessing drug toxicity risks in cancer treatment'
    ],
    referenceChart: [
      { weight: 65, height: 165, bsa: 1.72 },
      { weight: 75, height: 175, bsa: 1.90 },
      { weight: 85, height: 185, bsa: 2.08 },
      { weight: 95, height: 195, bsa: 2.26 },
      { weight: 105, height: 205, bsa: 2.44 }
    ]
  },
  {
    id: 'globalrph-bsa-calculator',
    name: 'GlobalRPh BSA Calculator',
    description: 'Calculate Body Surface Area using the GlobalRPh method.',
    method: 'mosteller',
    howToCalculate: 'GlobalRPh typically uses the Mosteller formula: BSA (m²) = √((Height(cm) x Weight(kg)) / 3600)',
    exampleCalculation: 'For a person weighing 70 kg and 170 cm tall: BSA = √((170 x 70) / 3600) = 1.81 m²',
    popularUses: [
      'Pharmacy calculations',
      'Medication dosing',
      'Clinical research'
    ],
    referenceChart: [
      { weight: 55, height: 155, bsa: 1.53 },
      { weight: 65, height: 165, bsa: 1.72 },
      { weight: 75, height: 175, bsa: 1.91 },
      { weight: 85, height: 185, bsa: 2.10 },
      { weight: 95, height: 195, bsa: 2.29 }
    ]
  },
  {
    id: 'bsa-in-m2-calculator',
    name: 'BSA in m² Calculator',
    description: 'Calculate Body Surface Area in square meters.',
    method: 'mosteller',
    howToCalculate: 'This calculator uses the Mosteller formula: BSA (m²) = √((Height(cm) x Weight(kg)) / 3600)',
    exampleCalculation: 'For a person weighing 75 kg and 175 cm tall: BSA = √((175 x 75) / 3600) = 1.91 m²',
    popularUses: [
      'Medical dosing calculations',
      'Comparing metabolic-related factors between individuals',
      'Estimating cardiac output'
    ],
    referenceChart: [
      { weight: 60, height: 160, bsa: 1.63 },
      { weight: 70, height: 170, bsa: 1.81 },
      { weight: 80, height: 180, bsa: 2.00 },
      { weight: 90, height: 190, bsa: 2.18 },
      { weight: 100, height: 200, bsa: 2.37 }
    ]
  },
  {
    id: 'bmi-calculator-medcalc',
    name: 'BMI Calculator MedCalc',
    description: 'Calculate Body Mass Index using the MedCalc method.',
    method: 'bmi',
    howToCalculate: 'BMI is calculated as: BMI = Weight(kg) / (Height(m))²',
    exampleCalculation: 'For a person weighing 70 kg and 1.75 m tall: BMI = 70 / (1.75)² = 22.86 kg/m²',
    popularUses: [
      'Assessing obesity',
      'Evaluating health risks related to weight',
      'Nutritional assessment in clinical settings'
    ],
    referenceChart: [
      { weight: 60, height: 160, bsa: 23.4 },
      { weight: 70, height: 170, bsa: 24.2 },
      { weight: 80, height: 180, bsa: 24.7 },
      { weight: 90, height: 190, bsa: 24.9 },
      { weight: 100, height: 200, bsa: 25.0 }
    ]
  },
  {
    id: 'body-surface-area-formula-using-weight-only',
    name: 'BSA Formula (Weight Only)',
    description: 'Calculate Body Surface Area using weight only.',
    method: 'weightOnly',
    howToCalculate: 'This method uses the formula: BSA (m²) = 0.1173 x Weight(kg)^0.6466',
    exampleCalculation: 'For a person weighing 70 kg: BSA = 0.1173 x 70^0.6466 = 1.81 m²',
    popularUses: [
      'Quick BSA estimation when height is unknown',
      'Emergency medicine scenarios',
      'Veterinary medicine applications'
    ],
    referenceChart: [
      { weight: 50, height: 'N/A', bsa: 1.54 },
      { weight: 60, height: 'N/A', bsa: 1.71 },
      { weight: 70, height: 'N/A', bsa: 1.87 },
      { weight: 80, height: 'N/A', bsa: 2.02 },
      { weight: 90, height: 'N/A', bsa: 2.17 }
    ]
  },
  {
    id: 'eviq-bsa-calculator',
    name: 'eviQ BSA Calculator',
    description: 'Calculate Body Surface Area using the eviQ method.',
    method: 'mosteller',
    howToCalculate: 'eviQ typically uses the Mosteller formula: BSA (m²) = √((Height(cm) x Weight(kg)) / 3600)',
    exampleCalculation: 'For a person weighing 75 kg and 175 cm tall: BSA = √((175 x 75) / 3600) = 1.91 m²',
    popularUses: [
      'Cancer treatment dosing',
      'Clinical trial dose calculations',
      'Standardizing drug doses in oncology'
    ],
    referenceChart: [
      { weight: 60, height: 160, bsa: 1.63 },
      { weight: 70, height: 170, bsa: 1.81 },
      { weight: 80, height: 180, bsa: 2.00 },
      { weight: 90, height: 190, bsa: 2.18 },
      { weight: 100, height: 200, bsa: 2.37 }
    ]
  },
  {
    id: 'body-surface-area-calculator-bnf',
    name: 'BSA Calculator (BNF)',
    description: 'Calculate Body Surface Area using the British National Formulary method.',
    method: 'mosteller',
    howToCalculate: 'The BNF recommends the Mosteller formula: BSA (m²) = √((Height(cm) x Weight(kg)) / 3600)',
    exampleCalculation: 'For a person weighing 70 kg and 170 cm tall: BSA = √((170 x 70) / 3600) = 1.81 m²',
    popularUses: [
      'Drug dosing in UK clinical practice',
      'Pharmacy calculations',
      'Pediatric medication adjustments'
    ],
    referenceChart: [
      { weight: 55, height: 155, bsa: 1.53 },
      { weight: 65, height: 165, bsa: 1.72 },
      { weight: 75, height: 175, bsa: 1.91 },
      { weight: 85, height: 185, bsa: 2.10 },
      { weight: 95, height: 195, bsa: 2.29 }
    ]
  },
  {
    id: 'bsa-calculator-for-psoriasis',
    name: 'BSA Calculator for Psoriasis',
    description: 'Calculate Body Surface Area affected by psoriasis.',
    method: 'psoriasis',
    howToCalculate: 'For psoriasis, BSA is often estimated using the "palm method": 1 palm = 1% of total BSA',
    exampleCalculation: 'If psoriasis covers an area equivalent to 4 palms: Affected BSA = 4%',
    popularUses: [
      'Assessing psoriasis severity',
      'Monitoring psoriasis treatment progress',
      'Clinical research in dermatology'
    ],
    referenceChart: [
      { palms: 1, percentBSA: '1%' },
      { palms: 2, percentBSA: '2%' },
      { palms: 5, percentBSA: '5%' },
      { palms: 10, percentBSA: '10%' },
      { palms: 20, percentBSA: '20%' }
    ]
  },
  {
    id: 'body-surface-area-calculator-schnur',
    name: 'BSA Calculator (Schnur)',
    description: 'Calculate Body Surface Area using the Schnur method.',
    method: 'schnur',
    howToCalculate: 'The Schnur formula: BSA (m²) = 0.00949 x Weight(kg)^0.441 x Height(cm)^0.655',
    exampleCalculation: 'For a person weighing 70 kg and 170 cm tall: BSA = 0.00949 x 70^0.441 x 170^0.655 = 1.80 m²',
    popularUses: [
      'Plastic surgery calculations',
      'Burn area estimations',
      'Research comparing different BSA formulas'
    ],
    referenceChart: [
      { weight: 60, height: 160, bsa: 1.62 },
      { weight: 70, height: 170, bsa: 1.80 },
      { weight: 80, height: 180, bsa: 1.98 },
      { weight: 90, height: 190, bsa: 2.16 },
      { weight: 100, height: 200, bsa: 2.34 }
    ]
  },
  {
    id: 'bsa-dose-calculator',
    name: 'BSA Dose Calculator',
    description: 'Calculate medication doses based on Body Surface Area.',
    method: 'mosteller',
    howToCalculate: 'First calculate BSA using Mosteller formula, then multiply by drug-specific mg/m² dose',
    exampleCalculation: 'For a person with BSA 1.8 m² and a drug dose of 100 mg/m²: Dose = 1.8 x 100 = 180 mg',
    popularUses: [
      'Chemotherapy dosing',
      'Pediatric medication calculations',
      'Clinical trial drug administration'
    ],
    referenceChart: [
      { bsa: 1.5, dose50: 75, dose100: 150, dose150: 225 },
      { bsa: 1.7, dose50: 85, dose100: 170, dose150: 255 },
      { bsa: 1.9, dose50: 95, dose100: 190, dose150: 285 },
      { bsa: 2.1, dose50: 105, dose100: 210, dose150: 315 },
      { bsa: 2.3, dose50: 115, dose100: 230, dose150: 345 }
    ]
  },
  {
    id: 'halls-bsa-calculator',
    name: 'Hall\'s BSA Calculator',
    description: 'Calculate Body Surface Area using Hall\'s method.',
    method: 'hall',
    howToCalculate: 'Hall\'s formula: BSA (m²) = 0.0235 x Height(cm)^0.42246 x Weight(kg)^0.51456',
    exampleCalculation: 'For a person weighing 70 kg and 170 cm tall: BSA = 0.0235 x 170^0.42246 x 70^0.51456 = 1.81 m²',
    popularUses: [
      'Clinical research',
      'Comparative studies of BSA formulas',
      'Specialized medical calculations'
    ],
    referenceChart: [
      { weight: 60, height: 160, bsa: 1.63 },
      { weight: 70, height: 170, bsa: 1.81 },
      { weight: 80, height: 180, bsa: 1.99 },
      { weight: 90, height: 190, bsa: 2.17 },
      { weight: 100, height: 200, bsa: 2.35 }
    ]
  },
  {
    id: 'bsa-calculator-for-dogs',
    name: 'BSA Calculator for Dogs',
    description: 'Calculate Body Surface Area for dogs.',
    method: 'dog',
    howToCalculate: 'For dogs, use the formula: BSA (m²) = 0.1 x Weight(kg)^(2/3)',
    exampleCalculation: 'For a dog weighing 20 kg: BSA = 0.1 x 20^(2/3) = 0.74 m²',
    popularUses: [
      'Veterinary drug dosing',
      'Canine metabolic studies',
      'Veterinary oncology calculations'
    ],
    referenceChart: [
      { weight: 5, bsa: 0.29 },
      { weight: 10, bsa: 0.46 },
      { weight: 20, bsa: 0.74 },
      { weight: 30, bsa: 0.97 },
      { weight: 40, bsa: 1.17 }
    ]
  }
];