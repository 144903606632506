// src/utils/calculations.js

// Mosteller formula for BSA
export function calculateMostellerBSA(weight, height) {
  return Math.sqrt((weight * height) / 3600);
}

// Du Bois and Du Bois formula for BSA
export function calculateDuBoisBSA(weight, height) {
  return 0.007184 * Math.pow(height, 0.725) * Math.pow(weight, 0.425);
}

// Haycock formula for BSA (often used for pediatrics)
export function calculateHaycockBSA(weight, height) {
  return 0.024265 * Math.pow(height, 0.3964) * Math.pow(weight, 0.5378);
}

// Gehan and George formula for BSA
export function calculateGehanGeorgeBSA(weight, height) {
  return 0.0235 * Math.pow(height, 0.42246) * Math.pow(weight, 0.51456);
}

// Boyd formula for BSA
export function calculateBoydBSA(weight, height) {
  return 0.0003207 * Math.pow(height, 0.3) * Math.pow(weight, (0.7285 - (0.0188 * Math.log10(weight))));
}

// Fujimoto formula for BSA
export function calculateFujimotoBSA(weight, height) {
  return 0.008883 * Math.pow(height, 0.663) * Math.pow(weight, 0.444);
}

// Takahira formula for BSA
export function calculateTakahiraBSA(weight, height) {
  return 0.007241 * Math.pow(height, 0.725) * Math.pow(weight, 0.425);
}

// Schlich formula for BSA
export function calculateSchlichBSA(weight, height) {
  return 0.000579479 * Math.pow(height, 1.24) * Math.pow(weight, 0.38);
}

// Weight-only BSA formula
export function calculateWeightOnlyBSA(weight) {
  return 0.1173 * Math.pow(weight, 0.6466);
}

// Schnur formula for BSA
export function calculateSchnurBSA(weight, height) {
  return 0.00949 * Math.pow(weight, 0.441) * Math.pow(height, 0.655);
}

// Hall's formula for BSA
export function calculateHallBSA(weight, height) {
  return 0.0235 * Math.pow(height, 0.42246) * Math.pow(weight, 0.51456);
}

// BSA calculation for dogs
export function calculateDogBSA(weight) {
  return 0.1 * Math.pow(weight, 2/3);
}

// BMI calculation
export function calculateBMI(weight, height) {
  const heightInMeters = height / 100;
  return weight / (heightInMeters * heightInMeters);
}

// Main BSA calculation function
export function calculateBSA(weight, height, method) {
  switch (method) {
    case 'mosteller':
      return calculateMostellerBSA(weight, height);
    case 'dubois':
      return calculateDuBoisBSA(weight, height);
    case 'haycock':
      return calculateHaycockBSA(weight, height);
    case 'gehan-george':
      return calculateGehanGeorgeBSA(weight, height);
    case 'boyd':
      return calculateBoydBSA(weight, height);
    case 'fujimoto':
      return calculateFujimotoBSA(weight, height);
    case 'takahira':
      return calculateTakahiraBSA(weight, height);
    case 'schlich':
      return calculateSchlichBSA(weight, height);
    case 'weightOnly':
      return calculateWeightOnlyBSA(weight);
    case 'schnur':
      return calculateSchnurBSA(weight, height);
    case 'hall':
      return calculateHallBSA(weight, height);
    default:
      return calculateMostellerBSA(weight, height);
  }
}

// Drug dosage calculations (mg/m2)
export function getDrugDosage(drug, bsa) {
  const dosages = {
    'doxorubicin': 60,
    'cisplatin': 75,
    'methotrexate': 15,
    'paclitaxel': 175,
    'carboplatin': 400,
    '5-fluorouracil': 1000
  };
  
  return dosages[drug] ? dosages[drug] * bsa : 0;
}

// BMI category
export function getBMICategory(bmi) {
  if (bmi < 18.5) return 'Underweight';
  if (bmi < 25) return 'Normal weight';
  if (bmi < 30) return 'Overweight';
  return 'Obese';
}
// Add this function to your existing calculations.js file

export function formatBSA(value) {
  const m2 = value.toFixed(4);
  const ft2 = (value * 10.764).toFixed(4);
  const in2 = (value * 1550).toFixed(4);
  return `${m2} m² | ${ft2} ft² | ${in2} in²`;
}
// Pediatric BSA percentile (simplified version)
export function calculatePercentile(bsa, age) {
  // This is a simplified version. In a real application, you would use 
  // standardized growth charts and interpolate between values.
  const bsaPercentiles = {
    0: {3: 0.20, 50: 0.22, 97: 0.24},
    1: {3: 0.41, 50: 0.47, 97: 0.52},
    5: {3: 0.65, 50: 0.78, 97: 0.91},
    10: {3: 0.97, 50: 1.15, 97: 1.35},
    15: {3: 1.26, 50: 1.54, 97: 1.84},
    18: {3: 1.43, 50: 1.74, 97: 2.09}
  };

  let closestAge = Object.keys(bsaPercentiles).reduce((prev, curr) => {
    return (Math.abs(curr - age) < Math.abs(prev - age) ? curr : prev);
  });

  let percentiles = bsaPercentiles[closestAge];
  
  if (bsa < percentiles[3]) return '<3rd';
  if (bsa < percentiles[50]) return '3rd-50th';
  if (bsa < percentiles[97]) return '50th-97th';
  return '>97th';
}