import React from 'react';
import { Helmet } from 'react-helmet';
import './PageStyles.css';

function AboutPage() {
  return (
    <div className="page-container">
      <Helmet>
        <title>About Us - BSA Calculator</title>
        <meta name="description" content="Learn about the team behind the BSA Calculator and our mission to provide accurate medical calculations." />
      </Helmet>
      <h1>About Us</h1>
      <h2>Our Mission</h2>
      <p>At BSA Calculator, our mission is to provide healthcare professionals, researchers, and students with a reliable and user-friendly tool for calculating Body Surface Area. We understand the critical role that accurate BSA calculations play in various medical applications, from drug dosing to metabolic assessments.</p>
      
      <h2>Who We Are</h2>
      <p>We are a team of medical professionals, software engineers, and data scientists passionate about improving healthcare through technology. Our diverse backgrounds allow us to approach the challenge of BSA calculation from multiple perspectives, ensuring a tool that is both scientifically accurate and intuitively usable.</p>
      
      <h2>Our Commitment to Accuracy</h2>
      <p>We are committed to providing the most accurate BSA calculations possible. Our calculators are based on peer-reviewed formulas and are regularly updated to reflect the latest research in the field. We understand that even small discrepancies can have significant implications in medical settings, which is why we prioritize precision in all our calculations.</p>
      
      <h2>Continuous Improvement</h2>
      <p>The field of medical calculation is ever-evolving, and so are we. We continuously gather feedback from our users and stay abreast of the latest developments in BSA calculation methodologies. This allows us to refine our tools and introduce new features that best serve the needs of our users.</p>
      
      <h2>Our Vision</h2>
      <p>We envision a future where accurate medical calculations are readily accessible to all healthcare providers, contributing to improved patient care and more efficient clinical practices. While our current focus is on BSA calculation, we aim to expand our suite of tools to cover a wider range of medical calculations in the future.</p>
      
      <h2>Contact Us</h2>
      <p>We value your feedback and are always eager to hear from our users. Whether you have a suggestion for improvement, a question about our calculators, or just want to share your experience, please don't hesitate to reach out to us through our <a href="/contact">contact page</a>.</p>
    </div>
  );
}

export default AboutPage;