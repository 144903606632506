import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const categories = [
    { id: 'bsa-calculator-for-methotrexate', name: 'BSA Calculator for Methotrexate' },
    { id: 'bsa-calculator-mosteller', name: 'BSA Calculator (Mosteller)' },
    { id: 'bsa-calculator-for-chemotherapy', name: 'BSA Calculator for Chemotherapy' },
    { id: 'pediatric-bsa-calculator', name: 'Pediatric BSA Calculator' },
    { id: 'halls-md-bmi-calculator', name: "Hall's MD BMI Calculator" },
    { id: 'body-surface-area-calculator-dubois', name: 'BSA Calculator (Du Bois)' },
    { id: 'bsa-m2-calculator', name: 'BSA m² Calculator' },
    { id: 'oncology-bsa-calculator', name: 'Oncology BSA Calculator' },
    { id: 'globalrph-bsa-calculator', name: 'GlobalRPh BSA Calculator' },
    { id: 'bsa-in-m2-calculator', name: 'BSA in m² Calculator' },
    { id: 'bmi-calculator-medcalc', name: 'BMI Calculator MedCalc' },
    { id: 'body-surface-area-formula-using-weight-only', name: 'BSA Formula (Weight Only)' },
    { id: 'eviq-bsa-calculator', name: 'eviQ BSA Calculator' },
    { id: 'body-surface-area-calculator-bnf', name: 'BSA Calculator (BNF)' },
    { id: 'bsa-calculator-for-psoriasis', name: 'BSA Calculator for Psoriasis' },
    { id: 'body-surface-area-calculator-schnur', name: 'BSA Calculator (Schnur)' },
    { id: 'bsa-dose-calculator', name: 'BSA Dose Calculator' },
    { id: 'halls-bsa-calculator', name: "Hall's BSA Calculator" },
    { id: 'bsa-calculator-for-dogs', name: 'BSA Calculator for Dogs' }
  ];

  return (
    <header className="header">
      <div className="header-content">
        <h1 className="header-title">
          <Link to="/">Online BSA Calculator</Link>
        </h1>
        <button className="menu-toggle" onClick={toggleMenu}>
          <span className="menu-toggle-icon"></span>
        </button>
        <nav className={`header-nav ${isMenuOpen ? 'open' : ''}`}>
          <Link to="/" onClick={toggleMenu}>Home</Link>
          <Link to="/about" onClick={toggleMenu}>About Us</Link>
          <Link to="/contact" onClick={toggleMenu}>Contact Us</Link>
          <Link to="/privacy-policy" onClick={toggleMenu}>Privacy Policy</Link>
          <div className="dropdown">
            <button className="dropbtn" onClick={toggleDropdown}>
              All Conversions
              <span className="dropdown-arrow"></span>
            </button>
            <div className={`dropdown-content ${isDropdownOpen ? 'show' : ''}`}>
              {categories.map((category) => (
                <Link 
                  key={category.id} 
                  to={`/${category.id}`} 
                  onClick={() => {
                    toggleDropdown();
                    toggleMenu();
                  }}
                >
                  {category.name}
                </Link>
              ))}
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;