import React, { useState } from 'react';
import './HomePage.css';

function HomePage() {
  const [weight, setWeight] = useState('');
  const [weightUnit, setWeightUnit] = useState('kg');
  const [height, setHeight] = useState('');
  const [heightUnit, setHeightUnit] = useState('cm');
  const [bsa, setBsa] = useState(null);

  const calculateBSA = () => {
    let weightInKg = weight;
    let heightInCm = height;

    if (weightUnit === 'lb') weightInKg = weight * 0.453592;
    if (weightUnit === 'g') weightInKg = weight / 1000;

    if (heightUnit === 'ft') heightInCm = height * 30.48;
    if (heightUnit === 'in') heightInCm = height * 2.54;
    if (heightUnit === 'm') heightInCm = height * 100;

    const bsaDuBois = 0.007184 * Math.pow(weightInKg, 0.425) * Math.pow(heightInCm, 0.725);
    const bsaMosteller = Math.sqrt((weightInKg * heightInCm) / 3600);
    const bsaHaycock = 0.024265 * Math.pow(weightInKg, 0.5378) * Math.pow(heightInCm, 0.3964);
    const bsaGehanGeorge = 0.0235 * Math.pow(weightInKg, 0.51456) * Math.pow(heightInCm, 0.42246);
    const bsaBoyd = 0.0003207 * Math.pow(weightInKg, 0.7285 - 0.0188 * Math.log10(weightInKg)) * Math.pow(heightInCm, 0.3);
    const bsaFujimoto = 0.008883 * Math.pow(weightInKg, 0.444) * Math.pow(heightInCm, 0.663);
    const bsaTakahira = 0.007241 * Math.pow(weightInKg, 0.425) * Math.pow(heightInCm, 0.725);
    const bsaSchlich = 0.0235 * Math.pow(weightInKg, 0.51456) * Math.pow(heightInCm, 0.42246);

    setBsa({
      duBois: bsaDuBois,
      mosteller: bsaMosteller,
      haycock: bsaHaycock,
      gehanGeorge: bsaGehanGeorge,
      boyd: bsaBoyd,
      fujimoto: bsaFujimoto,
      takahira: bsaTakahira,
      schlich: bsaSchlich
    });
  };

  const formatBSA = (value) => {
    const m2 = value.toFixed(4);
    const ft2 = (value * 10.764).toFixed(4);
    const in2 = (value * 1550).toFixed(4);
    return `${m2} m² | ${ft2} ft² | ${in2} in²`;
  };

  return (
    <div className="home-page">
      <section className="hero">
        <h1 className="hero-title">BSA Calculator</h1>
        <p className="hero-description">
          Calculate Body Surface Area easily with our BSA calculator. Simply enter the weight and height, select the units, and get the result instantly!
        </p>
      </section>

      <section className="calculator-section">
        <div className="conversion-card">
          <div className="input-container">
            <input
              type="number"
              placeholder="Enter weight"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
            <select value={weightUnit} onChange={(e) => setWeightUnit(e.target.value)}>
              <option value="kg">kg</option>
              <option value="lb">lb</option>
              <option value="g">g</option>
            </select>
          </div>
          <div className="input-container">
            <input
              type="number"
              placeholder="Enter height"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            />
            <select value={heightUnit} onChange={(e) => setHeightUnit(e.target.value)}>
              <option value="cm">cm</option>
              <option value="ft">ft</option>
              <option value="in">in</option>
              <option value="m">m</option>
            </select>
          </div>
          <button className="convert-button" onClick={calculateBSA}>
            Calculate BSA
          </button>
          {bsa && (
            <table className="result-table">
              <thead>
                <tr>
                  <th>Formula</th>
                  <th>Result</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>Du Bois</td><td>{formatBSA(bsa.duBois)}</td></tr>
                <tr><td>Mosteller</td><td>{formatBSA(bsa.mosteller)}</td></tr>
                <tr><td>Haycock</td><td>{formatBSA(bsa.haycock)}</td></tr>
                <tr><td>Gehan & George</td><td>{formatBSA(bsa.gehanGeorge)}</td></tr>
                <tr><td>Boyd</td><td>{formatBSA(bsa.boyd)}</td></tr>
                <tr><td>Fujimoto</td><td>{formatBSA(bsa.fujimoto)}</td></tr>
                <tr><td>Takahira</td><td>{formatBSA(bsa.takahira)}</td></tr>
                <tr><td>Schlich</td><td>{formatBSA(bsa.schlich)}</td></tr>
              </tbody>
            </table>
          )}
        </div>
      </section>

      <section className="how-to-calculate">
        <h2>How To Calculate BSA?</h2>
        <p>Body Surface Area (BSA) is an essential measurement used in various medical applications, including drug dosing, burn assessment, and cardiac output determination. Several formulas have been developed to calculate BSA, each with its own strengths and limitations. The choice of formula often depends on the specific clinical context and patient population.</p>
        <p>The most commonly used BSA formulas include the Du Bois and Du Bois, Mosteller, and Haycock formulas. These formulas typically use the patient's height and weight as inputs. While they may produce slightly different results, they are all considered valid for clinical use.</p>
        <p>To calculate BSA, you simply input the patient's weight and height into the chosen formula. Our calculator provides results from multiple formulas, allowing for easy comparison. It's important to note that while these formulas are widely used, they are estimations and may not be perfectly accurate for all body types.</p>
      </section>

      <section className="other-conversions">
      <h2>BSA Formula</h2>
        <p>The Body Surface Area (BSA) is calculated using various formulas. Here are some of the most commonly used BSA formulas:</p>
        
        <h3>Du Bois and Du Bois Formula:</h3>
        <p>BSA (m²) = 0.007184 × Weight^0.425 × Height^0.725</p>

        <h3>Mosteller Formula:</h3>
        <p>BSA (m²) = √((Weight × Height) / 3600)</p>

        <h3>Haycock Formula:</h3>
        <p>BSA (m²) = 0.024265 × Weight^0.5378 × Height^0.3964</p>

        <h3>Gehan and George Formula:</h3>
        <p>BSA (m²) = 0.0235 × Weight^0.51456 × Height^0.42246</p>

        <h3>Boyd Formula:</h3>
        <p>BSA (m²) = 0.0003207 × Weight^(0.7285 - 0.0188 × log(Weight)) × Height^0.3</p>

        <h3>Fujimoto Formula:</h3>
        <p>BSA (m²) = 0.008883 × Weight^0.444 × Height^0.663</p>

        <h3>Takahira Formula:</h3>
        <p>BSA (m²) = 0.007241 × Weight^0.425 × Height^0.725</p>

        <h3>Schlich Formula:</h3>
        <p>BSA (m²) = 0.000579479 × Weight^0.38 × Height^1.24</p>

        <p>Note: Weight is in kg and Height is in cm for all formulas.</p>
      </section>

      <section className="other-conversions">
        <h2>Other BSA Calculators</h2>
        <div className="conversions-grid">
          <a href="/bsa-calculator-for-methotrexate" className="conversion-link">
            <img src="https://i.ibb.co/y6sQKPg/Human-body-outline.png" alt="Icon" />
            <span>BSA Calculator for Methotrexate</span>
          </a>
          <a href="/bsa-calculator-mosteller" className="conversion-link">
            <img src="https://i.ibb.co/y6sQKPg/Human-body-outline.png" alt="Icon" />
            <span>BSA Calculator (Mosteller)</span>
          </a>
          <a href="/bsa-calculator-for-chemotherapy" className="conversion-link">
            <img src="https://i.ibb.co/y6sQKPg/Human-body-outline.png" alt="Icon" />
            <span>BSA Calculator for Chemotherapy</span>
          </a>
          <a href="/pediatric-bsa-calculator" className="conversion-link">
            <img src="https://i.ibb.co/y6sQKPg/Human-body-outline.png" alt="Icon" />
            <span>Pediatric BSA Calculator</span>
          </a>
          <a href="/halls-md-bmi-calculator" className="conversion-link">
            <img src="https://i.ibb.co/y6sQKPg/Human-body-outline.png" alt="Icon" />
            <span>Hall's MD BMI Calculator</span>
          </a>
          <a href="/body-surface-area-calculator-dubois" className="conversion-link">
            <img src="https://i.ibb.co/y6sQKPg/Human-body-outline.png" alt="Icon" />
            <span>BSA Calculator (Du Bois)</span>
          </a>
          <a href="/bsa-m2-calculator" className="conversion-link">
            <img src="https://i.ibb.co/y6sQKPg/Human-body-outline.png" alt="Icon" />
            <span>BSA (m²) Calculator</span>
          </a>
          <a href="/oncology-bsa-calculator" className="conversion-link">
            <img src="https://i.ibb.co/y6sQKPg/Human-body-outline.png" alt="Icon" />
            <span>Oncology BSA Calculator</span>
          </a>
          <a href="/globalrph-bsa-calculator" className="conversion-link">
            <img src="https://i.ibb.co/y6sQKPg/Human-body-outline.png" alt="Icon" />
            <span>GlobalRPh BSA Calculator</span>
          </a>
        </div>
      </section>

      <section className="bsa-chart">
        <h2>Body Surface Area Chart Table</h2>
        <table>
          <thead>
            <tr>
              <th>Age</th>
              <th>Male BSA (m²)</th>
              <th>Female BSA (m²)</th>
            </tr>
          </thead>
          <tbody>
            <tr><td>Newborn</td><td>0.25</td><td>0.24</td></tr>
            <tr><td>1 month</td><td>0.29</td><td>0.28</td></tr>
            <tr><td>6 months</td><td>0.40</td><td>0.38</td></tr>
            <tr><td>1 year</td><td>0.47</td><td>0.45</td></tr>
            <tr><td>2 years</td><td>0.54</td><td>0.52</td></tr>
            <tr><td>5 years</td><td>0.78</td><td>0.76</td></tr>
            <tr><td>10 years</td><td>1.14</td><td>1.12</td></tr>
            <tr><td>15 years</td><td>1.62</td><td>1.52</td></tr>
            <tr><td>20 years</td><td>1.92</td><td>1.71</td></tr>
            <tr><td>25 years</td><td>1.95</td><td>1.72</td></tr>
            <tr><td>30 years</td><td>1.96</td><td>1.73</td></tr>
          </tbody>
        </table>
        <p>Note: These values are approximate and based on average heights and weights for each age group. Individual BSA can vary significantly based on actual height and weight.</p>
      </section>
    </div>
  );
}

export default HomePage;