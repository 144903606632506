import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { calculateBSA, formatBSA } from '../utils/calculations';
import { calculators } from '../data/calculatorData';
import './BSACalculatorPage.css';

function BSACalculatorPage({ calculator }) {
  const { weight: weightParam } = useParams();
  const [weight, setWeight] = useState(weightParam || '');
  const [height, setHeight] = useState('170');
  const [bsa, setBsa] = useState(null);

  useEffect(() => {
    if (weightParam) {
      handleCalculate();
    }
  }, [weightParam]);

  const handleCalculate = () => {
    if (!weight) {
      alert('Please enter weight.');
      return;
    }
    const result = calculateBSA(parseFloat(weight), parseFloat(height), calculator.method);
    setBsa(result);
  };

  const renderNearbyWeights = () => {
    const currentWeight = parseInt(weight);
    return (
      <div className="nearby-weights">
        <h3>Nearby Weights</h3>
        {[-2, -1, 0, 1, 2].map(offset => {
          const nearbyWeight = currentWeight + offset;
          if (nearbyWeight >= 20 && nearbyWeight <= 220) {
            return (
              <Link key={offset} to={`/${calculator.id}/${nearbyWeight}`}>
                {nearbyWeight} kg
              </Link>
            );
          }
          return null;
        })}
      </div>
    );
  };

  return (
    <div className="bsa-calculator-page">
      <Helmet>
        <title>{`${calculator.name} for ${weight}kg - BSA Calculator`}</title>
        <meta name="description" content={`Calculate ${calculator.name} for ${weight}kg. Get accurate Body Surface Area results.`} />
      </Helmet>

      <section className="hero">
        <h1 className="hero-title">{calculator.name} for {weight}kg</h1>
        <p className="hero-description">{calculator.description}</p>
      </section>

      <section className="calculator-section">
        <div className="conversion-card">
          <div className="input-container">
            <input
              type="number"
              placeholder="Weight (kg)"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
            />
            <input
              type="number"
              placeholder="Height (cm)"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
            />
          </div>
          <button className="convert-button" onClick={handleCalculate}>
            Calculate BSA
          </button>
          {bsa && (
            <div className="result">
              <p>BSA: {formatBSA(bsa)}</p>
            </div>
          )}
        </div>
      </section>

      {renderNearbyWeights()}

      <section className="how-to-calculate">
        <h2>How to Calculate {calculator.name}</h2>
        <p>{calculator.howToCalculate}</p>
        <div className="calculation-example">
          <h3>Example Calculation:</h3>
          <p>{calculator.exampleCalculation}</p>
        </div>
      </section>

      <section className="other-calculators">
        <h2>Other BSA Calculators</h2>
        <div className="calculators-grid">
          {calculators.filter(calc => calc.id !== calculator.id).map(calc => (
            <Link key={calc.id} to={`/${calc.id}/${weight}`} className="calculator-link">
              <img src="https://i.ibb.co/y6sQKPg/Human-body-outline.png" alt="Icon" />
              <span>{calc.name}</span>
            </Link>
          ))}
        </div>
      </section>

      <section className="popular-items">
        <h2>Popular Uses for {calculator.name}</h2>
        <ul>
          {calculator.popularUses.map((use, index) => (
            <li key={index}>{use}</li>
          ))}
        </ul>
      </section>

      <section className="bsa-chart">
        <h2>BSA Reference Chart</h2>
        <table>
          <thead>
            <tr>
              <th>Weight (kg)</th>
              <th>Height (cm)</th>
              <th>BSA (m²)</th>
            </tr>
          </thead>
          <tbody>
            {calculator.referenceChart.map((row, index) => (
              <tr key={index}>
                <td>{row.weight}</td>
                <td>{row.height}</td>
                <td>{row.bsa}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default BSACalculatorPage;