import React from 'react';
import { Helmet } from 'react-helmet';
import './PageStyles.css';

function PrivacyPolicyPage() {
  return (
    <div className="page-container">
      <Helmet>
        <title>Privacy Policy - BSA Calculator</title>
        <meta name="description" content="Read our privacy policy to understand how we handle and protect your data when using the BSA Calculator." />
      </Helmet>
      <h1>Privacy Policy</h1>
      <p>Last updated: 21-08-2024</p>
      
      <h2>1. Introduction</h2>
      <p>At BSA Calculator, we are committed to protecting your privacy and ensuring the security of any personal information you provide. This Privacy Policy outlines our practices concerning the collection, use, and protection of your data when you use our BSA Calculator service.</p>
      
      <h2>2. Information We Collect</h2>
      <p>We want to assure you that we do not collect, store, or process any personal data through our BSA Calculator. All calculations are performed client-side in your browser, and no data is transmitted to our servers.</p>
      
      <h2>3. How We Use Information</h2>
      <p>Since we do not collect any personal information, we do not use your data for any purpose. The inputs you provide for calculations are processed in real-time on your device and are not retained or transmitted.</p>
      
      <h2>4. Data Security</h2>
      <p>While we do not collect or store your data, we have implemented appropriate technical and organizational measures to ensure the security of our website and protect it from unauthorized access, alteration, disclosure, or destruction.</p>
      
      <h2>5. Use of Cookies</h2>
      <p>Our website does not use cookies or similar tracking technologies.</p>
      
      <h2>6. Third-Party Links</h2>
      <p>Our website may contain links to third-party websites. Please note that we are not responsible for the privacy practices of these external sites. We encourage you to review the privacy policies of any website you visit via links from our site.</p>
      
      <h2>7. Children's Privacy</h2>
      <p>Our service is not directed to individuals under the age of 13. We do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete such information.</p>
      
      <h2>8. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this policy.</p>
      
      <h2>9. Contact Us</h2>
      <p>If you have any questions about this Privacy Policy or our practices, please contact us at [Your Contact Email].</p>
      
      <h2>10. Your Rights</h2>
      <p>While we do not collect personal data, we respect and uphold your rights under applicable data protection laws. These may include the right to access, correct, delete, or restrict the processing of your personal data, and the right to data portability.</p>
      
      <h2>11. Governing Law</h2>
      <p>This Privacy Policy is governed by and construed in accordance with the laws of [Your Country/State], without giving effect to any principles of conflicts of law.</p>
    </div>
  );
}

export default PrivacyPolicyPage;